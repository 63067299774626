import './App.css';
import firebase from 'firebase/app';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Reservation from './pages/Reservation';
import ReservationStatus from './pages/ReservationStatus';
import ReservationCancel from './pages/ReservationCancel';
import NotFound from './pages/NotFound';

firebase.initializeApp(require(process.env.REACT_APP_FIREBASE).default);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/:restoName" component={Reservation} />
          <Route exact path="/:restoName/:id" component={ReservationStatus} />
          <Route exact path="/:restoName/cancel/:id" component={ReservationCancel} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
