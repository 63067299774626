// write a function component named ReservationCancel. This compoment should render a div element with the text "Reservation Cancelled". The component should be exported as default.

import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { format } from 'date-fns';

const getResto = async (restoName) => {
  const docs = await firebase.firestore()
    .collection('Restaurant')
    .where('urlName', '==', restoName)
    .get();

  if (!docs.empty) {
    const data = {
      id: docs.docs[0].id,
      ...docs.docs[0].data(),
    }

    return data;
  }
  else {
    return null;
  }
}

const ReservationCancel = (props) => {
  const restoName = props.match.params.restoName;
  const reservationId = props.match.params.id;

  const [resto, setResto] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    getResto(restoName.toLowerCase()).then(resto => {
      if (resto === null)
        setError('Le restaurant n\'existe pas');
      else {
        setResto(resto);
      }
    });
  }, [restoName]);

  useEffect(() => {
    if (resto !== null) {
      firebase.firestore()
        .collection('Restaurant')
        .doc(resto.id)
        .collection('Reservation')
        .doc(reservationId)
        .onSnapshot(doc => {
          if (doc.exists) {
            setReservation({
              id: doc.id,
              ...doc.data(),
            });
          }
          else {
            setReservation(null);
          }
        });
    }
  }, [resto?.id, reservationId, resto?.status]);

  useEffect(() => {
    if (resto !== null)
      document.title = 'Reservation ' + resto.reservationSettings.restoName;
  }, [resto]);

  const cancelReservation = async () => {
    // update firebase reservation status to 5
    await firebase.firestore()
      .collection('Restaurant')
      .doc(resto.id)
      .collection('Reservation')
      .doc(reservation.id)
      .update({ status: 5 });
  }

  const getStatus = (status) => {
    if (status === 1)
      return 'En attente';
    else if (status === 2)
      return 'Confirmée';
    else if (status === 3)
      return 'Annulée';
    else if (status === 4)
      return 'Annulée';
    else if (status === 5)
      return 'Annulée';
  }

  if (reservation !== null) {
    const date = new Date(reservation.reservationDate.seconds * 1000 + reservation.reservationDate.nanoseconds / 1000000);
    const readableDate = format(date, 'dd-MM-yyyy à HH:mm');
    return (
      <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
        <div className="text-xl font-medium text-black">Reservation {resto.reservationSettings.restoName} du {readableDate}</div>
        <div className="text-gray-500">{reservation.firstname} {reservation.lastname}</div>
        <div className="text-gray-500">{reservation.nbAdult} personnes</div>
        <div className="text-gray-500">Status de votre reservation: {getStatus(reservation.status)}</div>
        {reservation.status !== 3 && reservation.status !== 5 && (
          <div>
            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
              onClick={cancelReservation}
            >
              Annuler votre réservation
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-xl shadow-md space-y-4">
      Reservation Cancelled
    </div>
  );

}

export default ReservationCancel;
